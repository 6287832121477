import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses, clsx } from 'styles';
import FacebookIcon from 'react-app/icons/FacebookCircle';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PinterestIcon from '@mui/icons-material/Pinterest';
import YouTubeIcon from '@mui/icons-material/YouTube';
import ShopIcon from '@mui/icons-material/ShoppingCart';
import HqIcon from 'components/HqIcon';
import ContactIcon from '@mui/icons-material/QuestionAnswer';
import IconButton from '@mui/material/IconButton';
import Link from 'components/Link';
import { appData } from 'config/data.cjs';

const useClasses = createClasses((theme, {defaultAppearance}) => ({
	root: {
		display: 'inline-flex',
		alignSelf: 'center',
		...(!defaultAppearance && {
			padding: theme.spacing(1),
			margin: theme.spacing(0, .5),
			fontSize: '2rem',
			color: 'inherit',
			transition: theme.transitions.create(['color', 'background', 'opacity'], {
				easing: theme.transitions.easing.easeInOut,
				duration: theme.transitions.duration.standart
			})
		})
	},
	facebook: {
		'&:hover, &:focus, &:active': {
			color: theme.config.darkMode ? 'inherit' : theme.config.facebookColor
		}
	},
	instagram: {
		'&:hover, &:focus, &:active': {
			color: theme.config.darkMode ? 'inherit' : theme.config.instagramColor
		}
	},
	pinterest: {
		'&:hover, &:focus, &:active': {
			color: theme.config.darkMode ? 'inherit' : theme.config.pinterestColor,
		}
	},
	whatsApp: {
		'&:hover, &:focus, &:active': {
			color: theme.config.darkMode ? 'inherit' : theme.config.whatsAppColor
		}
	},
	youtube: {
		'&:hover, &:focus, &:active': {
			color: theme.config.darkMode ? 'inherit' : theme.config.youtubeColor
		}
	},
	shop: {
		'&:hover, &:focus, &:active': {
			color: theme.config.darkMode ? 'inherit' : theme.config.palette.primary.main
		}
	},
	contact: {},
	hq: {
		color: theme.palette.error.main
	}
}), {
	name: 'ContactIcons'
});

const LinkProps = {
	component: 'a',
	target: '_blank',
	rel: 'noopener noreferrer'
};

const IconButtonProps = {
	color: 'inherit'
};

function ContactIcons(props) {
	const {
		classes: classesProp,
		className,
		facebook = true,
		instagram = true,
		youtube = true,
		pinterest = false,
		shop = true,
		whatsApp = false,
		contact = false,
		hq = false,
		defaultAppearance = false,
		...rest
	} = props;

	const classes = useClasses(props);

	return (
		<>
			{shop && (
				<>
					<IconButton
						{...IconButtonProps}
						{...LinkProps}
						{...rest}
						title="Unser Online-Shop"
						href={appData.contact.shop}
						className={clsx(
							classes.root,
							classes.shop,
							className
						)}
					>
						<ShopIcon fontSize="inherit"/>
					</IconButton>
				</>
			)}

			{facebook && (
				<IconButton
					{...IconButtonProps}
					{...LinkProps}
					{...rest}
					title="Facebook"
					href={appData.contact.facebook}
					className={clsx(
						classes.root,
						classes.facebook,
						className
					)}
				>
					<FacebookIcon fontSize="inherit"/>
				</IconButton>
			)}

			{instagram && (
				<IconButton
					{...IconButtonProps}
					{...LinkProps}
					{...rest}
					title="Instagram"
					href={appData.contact.instagram}
					className={clsx(
						classes.root,
						classes.instagram,
						className
					)}
				>
					<InstagramIcon fontSize="inherit"/>
				</IconButton>
			)}

			{pinterest && (
				<IconButton
					{...IconButtonProps}
					{...LinkProps}
					{...rest}
					title="Pinterest"
					href={appData.contact.pinterest}
					className={clsx(
						classes.root,
						classes.pinterest,
						className
					)}
				>
					<PinterestIcon fontSize="inherit"/>
				</IconButton>
			)}

			{youtube && (
				<IconButton
					{...IconButtonProps}
					{...LinkProps}
					{...rest}
					title="YouTube"
					href={appData.contact.youtube}
					className={clsx(
						classes.root,
						classes.youtube,
						className
					)}
				>
					<YouTubeIcon fontSize="inherit"/>
				</IconButton>
			)}

			{whatsApp && (
				<IconButton
					{...IconButtonProps}
					{...LinkProps}
					{...rest}
					title="WhatsApp"
					href={appData.contact.whatsApp}
					className={clsx(
						classes.root,
						classes.whatsApp,
						className
					)}
				>
					<WhatsAppIcon fontSize="inherit"/>
				</IconButton>
			)}

			{hq && (
				<IconButton
					{...IconButtonProps}
					{...rest}
					component={Link}
					title={appData.pages.hq.title}
					href={appData.pages.hq.pathname}
					className={clsx(
						classes.root,
						classes.hq,
						className
					)}
				>
					<HqIcon fontSize="inherit"/>
				</IconButton>
			)}

			{contact && (
				<IconButton
					{...IconButtonProps}
					{...rest}
					component={Link}
					title="Kontakt & Anfahrt"
					to={appData.pages.contact.pathname}
					className={clsx(
						classes.root,
						classes.contact,
						className
					)}
				>
					<ContactIcon fontSize="inherit"/>
				</IconButton>
			)}
		</>
	);
}

ContactIcons.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string
};

export default React.memo(ContactIcons);
